const mode = "dev"; // Define mode separately

const appConfig = {
  mode,
  apiPrefix:
    mode === "dev"
      ? "https://sssapi.brainware-india.net/"
      : "https://sssapi.brainwareuniversity.ac.in/",
  backendApiPrefix: "https://www.brainwareuniversity.ac.in/studentselfservice/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
};

export default appConfig;
